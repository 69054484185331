import { FC, ReactNode, useState } from 'react';
import { Route, useHistory } from 'react-router-dom';
import { isMobile } from 'react-device-detect';

import styles from './Patients.module.scss';

import {
  Button,
  Modal,
  notification,
  Popover,
  Result,
  Table,
  Typography,
} from 'antd';
import { PhoneOutlined } from '@ant-design/icons';

import useStore from '../../../../hooks/useStore';
import {
  ddmmyyyyToDate,
  downloadFile,
  toInitials,
} from '../../../../utils/commonHelpers';
import { FilesApi } from '../../../../api/files';
import { StudyFile } from '../../../../components';
import ReOffer from '../../../offer/ReOffer';
import FileActionButtons from './components/FileActionButtons';
import { IPatient, IStudyDownload } from '../../../../store/patient';
import { baseurl } from '../../../../api/baseurl';
import IsoFileActionButtons from './components/IsoFileActionButtons';

interface PatientsProps {
  patients: IPatient[];
  loading: boolean;
  error: string;
}

const downloadsColumns = (
  patient: IPatient,
  handleFileShow: (studyUUID: string, fileUUID: string) => void,
  handleFileDownload: (studyUUID: string, download: IStudyDownload) => void,
  handleFilePrepare: (studyUUID: string) => void,
  downloadFileLoading: string | null
) => {
  const donwloadActions = (download: IStudyDownload) => {
    const isFilm = download.type === 'Плівка';
    const isIso = download.type === 'Образ диску';

    if (isIso) {
      return (
        <IsoFileActionButtons
          message={download.message}
          isoStatus={download.isoStatus}
          onFileDownload={() => handleFileDownload(patient.studyUUID, download)}
          onFilePrepare={() => handleFilePrepare(patient.studyUUID)}
          downloadFileLoading={download.fileUUID === downloadFileLoading}
        />
      );
    }

    return download.fileUUID ? (
      <FileActionButtons
        onFileDownload={() => handleFileDownload(patient.studyUUID, download)}
        onFileShow={
          isFilm
            ? undefined
            : () => handleFileShow(patient.studyUUID, download.fileUUID)
        }
        downloadFileLoading={download.fileUUID === downloadFileLoading}
      />
    ) : undefined;
  };

  return [
    {
      dataIndex: 'downloads',
      key: 'downloads',
      responsive: ['xs'],
      render: (_: any, download: IStudyDownload) => {
        return (
          <div
            style={{
              display: 'flex',
              flexDirection: 'column',
              gap: 10,
            }}
          >
            <div
              style={{
                display: !!download.type ? 'block' : 'none',
                marginTop: 1,
              }}
            >
              {download.type}
            </div>
            <div style={{ display: !download.services ? 'none' : 'block' }}>
              {download.services}
            </div>
            <div>{donwloadActions(download)}</div>
          </div>
        );
      },
    },
    {
      dataIndex: 'downloads',
      key: 'downloads',
      responsive: ['sm'],
      render: (_: any, download: IStudyDownload) => {
        return (
          <div className={styles.downloads}>
            <div style={{ display: !!download.type ? 'block' : 'none' }}>
              {download.type}
            </div>
            <div style={{ display: !download.services ? 'none' : 'block' }}>
              {download.services}
            </div>
          </div>
        );
      },
    },
    {
      dataIndex: 'downloads',
      key: 'downloads',
      width: '50%',
      responsive: ['md'],
      render: (_: any, download: IStudyDownload) => {
        return (
          <div>
            <div>{download.type}</div>
            <div style={{ paddingLeft: 20 }}>{download.services}</div>
          </div>
        );
      },
    },
    {
      title: 'Action',
      key: 'action',
      responsive: ['sm'],
      render: (download: IStudyDownload) => {
        return donwloadActions(download);
      },
    },
  ];
};

const Patients: FC<PatientsProps> = ({ patients, loading, error }) => {
  const { cashFiles, patientsStore } = useStore();
  const history = useHistory();

  const [downloadFileLoading, setDownloadFileLoading] = useState<string | null>(
    null
  );
  const [offerModal, setOfferModal] = useState<IPatient | null>(null);

  const handleFilePrepare = async (studyUUID: string) => {
    await patientsStore.buildStudyImage(studyUUID);
  };

  const handleFileDownload = async (
    studyUUID: string,
    download: IStudyDownload
  ) => {
    try {
      if (download.storage === 'local') {
        window.location.href = baseurl + '/studies/local/' + download.fileUUID;
        return;
      }
      setDownloadFileLoading(download.fileUUID);
      let file;
      file = cashFiles.getFile(download.fileUUID);
      if (file) {
        downloadFile(file.blobUrl, file.name);
        return;
      }
      const { url, filename } = await FilesApi.getFileById(
        studyUUID,
        download.fileUUID
      );
      cashFiles.addFile({
        serverUrl: download.fileUUID,
        blobUrl: url,
        name: filename,
      });
      downloadFile(url, filename);
    } catch (e) {
      notification.error({
        message: 'Файл недоступний, зверніться до діагностичного центру',
      });
    } finally {
      setDownloadFileLoading(null);
    }
  };

  const handleFileShow = async (studyUUID: string, fileUUID: string) => {
    history.push(`/studies/${studyUUID}/file/${fileUUID}`);
  };

  const checkStudyImage = async (patient: IPatient) => {
    const isoFile = patient.downloads.find(
      (download) => download.type === 'Образ диску'
    );
    if (
      isoFile &&
      isoFile.fileUUID === 'checkStudyImage' &&
      !isoFile.isoStatus
    ) {
      await patientsStore.checkFileStatus(patient.studyUUID);
    }
  };

  const handleExpand = (open: boolean, patient: IPatient) => {
    if (open) {
      checkStudyImage(patient);
    }
  };

  const tableColumns = [
    {
      title: 'Дата',
      dataIndex: 'date',
      key: 'date',
      sorter: (a: IPatient, b: IPatient) => {
        return (
          ddmmyyyyToDate(a.date).getTime() - ddmmyyyyToDate(b.date).getTime()
        );
      },
      defaultSortOrder: 'descend',
      width: '10%',
    },
    {
      title: 'Пацієнт',
      dataIndex: 'patientName',
      key: 'patientName',
      width: '10%',
      sorter: (a: IPatient, b: IPatient) => {
        if (a.patientName < b.patientName) {
          return -1;
        }
        if (a.patientName > b.patientName) {
          return 1;
        }
        return 0;
      },
      render(text: string, record: IPatient) {
        return {
          children: (
            <Popover
              content={
                <div>
                  <Typography>
                    <b>Повне ім'я:</b> {record.patientName}
                  </Typography>
                  <b>Номер телефону: </b>
                  <Button
                    href={`tel:${record.patientPhone}`}
                    type="link"
                    icon={<PhoneOutlined />}
                  >
                    {record.patientPhone}
                  </Button>
                  <Typography>
                    <b>Дата народження:</b> {record.patientBirthDate}
                  </Typography>
                </div>
              }
            >
              {toInitials(text)}
            </Popover>
          ),
        };
      },
    },
    {
      title: 'Послуги',
      dataIndex: 'study',
      key: 'study',
    },
    {
      title: '',
      key: 'action',
      render: (_: any, record: IPatient) => (
        <Button
          className={styles.reofferButton}
          size={'small'}
          onClick={() => setOfferModal(record)}
        >
          Повторний запис
        </Button>
      ),
    },
  ];

  const tableData = patients.map((patient) => {
    return {
      ...patient,
      key: patient.uuid,
    };
  });

  const createTableData = (array: any) => {
    // Iterate over the dataSource array to find unique types
    // Group objects by type
    const groupedData: Record<string, IStudyDownload[]> = array.reduce(
      (acc: any, obj: any) => {
        // Extract the type from the object
        const { type, ...rest } = obj;
        // Check if the type already exists in the accumulator
        if (type in acc) {
          // If the type exists, push the object to its array
          acc[type].push(rest);
        } else {
          // If the type doesn't exist, create a new array with the object
          acc[type] = [rest];
        }
        return acc;
      },
      {}
    );

    const tableData = Object.entries(groupedData).map(([type, items]) =>
      items.length > 1
        ? {
            key: type,
            type,
            children:
              items.length > 1
                ? items.map((item) => ({
                    key: item.fileUUID,
                    ...item,
                  }))
                : undefined,
          }
        : {
            key: type,

            type,
            fileUUID: items[0].fileUUID,
            storage: items[0].storage,
            isoStatus: items[0].isoStatus,
            message: items[0].message,
            services: items[0].services,
          }
    );

    return tableData;
  };

  return (
    <>
      {error && <Result status="error" subTitle={error} />}
      <Table
        className={styles.table}
        dataSource={tableData}
        bordered
        size={isMobile ? 'small' : undefined}
        loading={loading} //@ts-ignore
        columns={tableColumns} //@ts-ignore
        expandRowByClick
        onExpand={handleExpand}
        expandedRowRender={(patient: IPatient): ReactNode => (
          <Table
            //@ts-ignore
            columns={downloadsColumns(
              patient,
              handleFileShow,
              handleFileDownload,
              handleFilePrepare,
              downloadFileLoading
            )}
            size="small"
            showHeader={false}
            //@ts-ignore
            dataSource={createTableData(
              patient.downloads
                .filter((download) => download.type !== 'dicom image')
                .map((s) => ({
                  ...s,
                  key: s.fileUUID,
                }))
            )}
            pagination={false}
          />
        )}
        pagination={false}
      />
      <Route
        exact
        path={'/studies/:studyUUID/file/:fileUUID'}
        component={StudyFile}
      />
      <Modal
        width={1000}
        title={
          offerModal && (
            <div>
              <div>Повторний запис</div>
              <div>
                Пацієнт: <b>{offerModal.patientName}</b>
              </div>
            </div>
          )
        }
        visible={!!offerModal}
        onCancel={() => setOfferModal(null)}
        footer={false}
      >
        {offerModal && (
          <ReOffer
            handleSuccess={() => setOfferModal(null)}
            studyUUID={offerModal.studyUUID}
          />
        )}
      </Modal>
    </>
  );
};

export default Patients;

import { FC, useState } from 'react';

import { Alert, Button } from 'antd';

import styles from '../Patients.module.scss';

import { IsoStatus } from '../../../../../store/patient';

interface FileActionButtonsProps {
  onFilePrepare: () => void;
  onFileDownload: () => void;
  downloadFileLoading: boolean;
  message?: string;
  isoStatus?: IsoStatus;
}

const IsoFileActionButtons: FC<FileActionButtonsProps> = ({
  isoStatus,
  onFilePrepare,
  onFileDownload,
  message,
  downloadFileLoading,
}) => {
  const [isMessage, setIsMessage] = useState(false);

  const handleDownload = () => {
    setIsMessage(true);
    onFileDownload();
  };

  return (
    <div className={styles.actionButtons}>
      {isoStatus === 'none' ? (
        <div className={styles.iso}>
          <Button
            size={'small'}
            style={{ marginRight: 10 }}
            onClick={onFilePrepare}
          >
            Підготувати образ диску
          </Button>
          {message && <Alert message={message} type="success" closable />}
        </div>
      ) : isoStatus === 'ready' ? (
        <div className={styles.iso}>
          <Button
            disabled={isMessage}
            size={'small'}
            style={{ marginRight: 10 }}
            onClick={handleDownload}
          >
            Завантажити образ диску
          </Button>
          {message && isMessage && (
            <Alert message={message} type="success" closable />
          )}
        </div>
      ) : isoStatus === 'creating' ? (
        <div className={styles.iso}>
          <Button disabled={true} size={'small'} style={{ marginRight: 10 }}>
            Диск створюється...
          </Button>
          {message && <Alert message={message} type="info" closable />}
        </div>
      ) : isoStatus === 'error' ? (
        <div className={styles.iso}>
          <Button disabled={true} size={'small'} style={{ marginRight: 10 }}>
            Помилка створення диску
          </Button>
          {message && <Alert message={message} type="error" closable />}
        </div>
      ) : null}
    </div>
  );
};

export default IsoFileActionButtons;

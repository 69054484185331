import { flow, Instance, types } from 'mobx-state-tree';
import { LoadingStatus } from './types';
import { FilesApi } from '../api/files';
import { pause } from '../utils/pause';
import { PatientsApi } from '../api/patients';

export type IsoStatus = 'none' | 'creating' | 'ready' | 'error' | 'unknown';
export type IsoStorage = 'mis' | 'local';

const StudyDownload = types.model('StudyDownload', {
  type: types.string,
  fileUUID: types.string,
  storage: types.maybeNull(types.enumeration<IsoStorage>(['mis', 'local'])),
  isoStatus: types.maybe(
    types.enumeration<IsoStatus>([
      'none',
      'creating',
      'ready',
      'error',
      'unknown',
    ])
  ),
  message: types.maybe(types.string),
  services: types.maybe(types.string),
});

export interface IStudyDownload extends Instance<typeof StudyDownload> {}

const Patient = types.model('Patient', {
  uuid: types.string,
  date: types.string,
  patientID: types.number,
  patientName: types.string,
  patientPhone: types.string,
  patientBirthDate: types.string,
  studyUUID: types.string,
  study: types.string,
  downloads: types.array(StudyDownload),
});

export interface IPatient extends Instance<typeof Patient> {}

const PatientStore = types
  .model('PatientStore', {
    patients: types.optional(types.array(Patient), []),
    getPatientsLoadingStatus: types.optional(
      types.enumeration<LoadingStatus>(Object.values(LoadingStatus)),
      LoadingStatus.NEVER
    ),
    getPatientError: types.optional(types.string, ''),
    unauthorizedError: types.optional(types.boolean, false),
  })
  .actions((self) => {
    const setGetPatientLoadingStatus = (loadingStatus: LoadingStatus) => {
      self.getPatientsLoadingStatus = loadingStatus;
    };

    const setGetPatientError = (errorMessage: string) => {
      self.getPatientError = errorMessage;
    };

    const getPatients = flow(function* () {
      try {
        setGetPatientLoadingStatus(LoadingStatus.LOADING);
        self.patients = yield PatientsApi.getPatients();
        setGetPatientLoadingStatus(LoadingStatus.SUCCESS);
      } catch (e: any) {
        console.log(e);
        self.getPatientError = e.message;
        setGetPatientLoadingStatus(LoadingStatus.ERROR);
      }
    });

    const getStudyDownload = (studyUUID: string | number) => {
      for (const patient of self.patients) {
        if (patient.studyUUID === studyUUID) {
          for (const download of patient.downloads) {
            if (download.type === 'Образ диску') {
              return download;
            }
          }
        }
      }
    };

    // @ts-ignore
    const checkFileStatus = flow(function* (studyUUID: string | number) {
      try {
        const { data } = yield FilesApi.checkIsoFileStatus(studyUUID);
        const studyDownload = getStudyDownload(studyUUID);
        if (studyDownload) {
          studyDownload.isoStatus = data.iso_status;
          studyDownload.message = data.message;

          if (studyDownload.isoStatus === 'ready') {
            studyDownload.fileUUID = data.fileUUID;
            studyDownload.storage = data.storage;
          }
        }
      } catch (e: any) {}
    });

    const buildStudyImage = flow(function* (studyUUID: string | number) {
      try {
        const { data } = yield FilesApi.buildIsoFile(studyUUID);
        const studyDownload = getStudyDownload(studyUUID);
        if (studyDownload) {
          studyDownload.isoStatus = data.iso_status;
          studyDownload.message = data.message;

          while (studyDownload.isoStatus === 'creating') {
            yield pause(60000);
            yield checkFileStatus(studyUUID);
          }
        }
      } catch (e: any) {}
    });

    return {
      getPatients,
      setGetPatientLoadingStatus,
      setGetPatientError,
      buildStudyImage,
      checkFileStatus,
    };
  });

export default PatientStore;
